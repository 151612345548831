.portfolio-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.portfolio-header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.portfolio-header .picture {
  margin-right: 25px;
}

.portfolio-header .picture img {
  border-radius: 100%;
}

.portfolio-description .title {
  color: #000000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}

.portfolio-description .description {
  color: #000000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
}

.portfolio-controls {
  display: flex;
  justify-content: space-evenly;
}

.portfolio-controls .control {
  opacity: 0.4;
  color: #4e5457;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}

.portfolio-controls .control.active {
  opacity: 1;
  color: #4858e9;
}

.portfolio .gallery {
  background-color: #f6fafb;
}

.portfolio .edit-link {
  text-decoration: underline;
}

.portfolio.edit-view .gallery img {
  max-width: 100%;
  border-radius: 8px;
}

.portfolio.edit-view .gallery img.active {
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.34);
}
