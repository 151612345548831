.datetimepicker.open {
  background-color: #fff;
}

.datetimepicker.open {
  position: absolute;
  width: 100%;
  z-index: 10;
  margin-bottom: 30px;
}

.datetimepicker .btn {
  padding: 5px 30px;
  height: 50px;
  width: 150px;
}

.datetimepicker .dt-tag {
  border-radius: 9px;
  background-color: #4858e9;
  color: #fff;
  margin-right: 5px;
  opacity: 0.5;
  padding: 5px 10px;
}

.datetimepicker .lower {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.datetimepicker-input {
  background-color: #ffffff;
  border: 1px solid rgba(0, 26, 63, 0.1);
  border-radius: 8px;
  color: #7f7f7f;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 20px;
}

.datetimepicker.open .datetimepicker-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* React Calendar style override */
.react-calendar {
  border: 0;
  padding: 0 10px;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #4858e9;
  border-radius: 100%;
  outline: 0;
}

.react-calendar__tile--now {
  background: #b8bfc9;
}

.timepicker > input {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 15px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Overrides for React datepicker */

.react-date-picker {
  width: 100%;
}

.react-date-picker__wrapper {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-date-picker__inputGroup {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 15px;
}

.label {
  padding-left: 20px;
  color: #4e5457;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}
