.radiobutton {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.radiobutton-group .radiobutton {
  display: inline-block;
  margin-right: 30px;
}

.radiobutton label,
.radiobutton-inline label {
  font-size: 15px;
}

.radiobutton > label {
  display: block;
  margin-left: 30px;
}

.radiobutton-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.radiobutton-wrapper {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
  position: relative;
}

.radiobutton-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em !important;
  height: 1em !important;
  margin-right: 1em;
}

.radiobutton-inline .radiobutton-wrapper {
  position: relative;
  top: inherit;
  left: inherit;
}

.radiobutton-wrapper input {
  position: absolute;
  top: auto;
  left: -9999em;
}
.radiobutton-wrapper input:checked + label:before {
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
  border-radius: 100%;
}
.radiobutton-wrapper input:checked + label:after {
  border: 2px solid #002d5a;
}
.radiobutton-wrapper input:disabled + label {
  cursor: not-allowed;
}
.radiobutton-wrapper input:disabled + label:before {
  background-color: transparent;
  cursor: not-allowed;
}
.radiobutton-wrapper input:disabled + label:after {
  border-color: #ccc !important;
  background-color: #eee;
  cursor: not-allowed;
}
.radiobutton-wrapper input:disabled:checked + label {
  cursor: not-allowed;
}
.radiobutton-wrapper input:disabled:checked + label:before {
  background-color: #002d5a;
  cursor: not-allowed;
}
.radiobutton-wrapper input:disabled:checked + label:after {
  border-color: #002d5a !important;
  background-color: transparent;
  cursor: not-allowed;
}
.radiobutton-wrapper label:after,
.radiobutton-wrapper label:before {
  top: 0;
}
.radiobutton-wrapper label:after {
  height: 20px;
  width: 20px;
  border-radius: 0.65em;
  border: 2px solid #aaa;
  cursor: pointer;
}
.radiobutton-wrapper label:after,
.radiobutton-wrapper label:before {
  position: absolute;
  content: ' ';
  display: block;
  transition: all 0.1s ease-out;
}
.radiobutton-wrapper label:before {
  height: 0;
  width: 0;
  background-color: #002d5a;
  top: 0.65em;
  left: 0.65em;
  border-radius: 0;
}

.checkbox {
  position: relative;
}

.checkbox .control-label {
  display: inline-block;
  font-size: 15px;
  margin-bottom: 0.5rem;
}

.checkbox-wrapper {
  display: inline-block;
  position: relative;
  width: 1em !important;
  height: 1em !important;
  margin-right: 1em;
}
.checkbox-wrapper input {
  position: absolute;
  top: auto;
  left: -9999em;
}
.checkbox-wrapper input:disabled + label {
  cursor: not-allowed;
}
.checkbox-wrapper input:disabled + label:before {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.75;
}
.checkbox-wrapper label:after,
.checkbox-wrapper label:before {
  top: 0;
}
.checkbox-wrapper label:before {
  content: ' ';
  border-radius: 0.13em;
  border: 2px solid #aaa;
  cursor: pointer;
}
.checkbox-wrapper label:after,
.checkbox-wrapper label:before {
  position: absolute;
  display: block;
  height: 1.3em;
  width: 1.3em;
  transition: all 0.1s ease-out;
}
.checkbox-wrapper label:after {
  font-family: Material Icons;
  content: '\E5CA';
  color: transparent;
  line-height: 1.3em;
  text-align: center;
}
.checkbox-wrapper input:checked + label:before {
  border: 0.65em solid #002d5a;
  background-color: #002d5a;
}
.checkbox-wrapper input:checked + label:after {
  color: #fff;
}
.checkbox-wrapper.toggle label {
  margin-right: 3.3em;
}
.checkbox-wrapper.toggle label:before {
  width: 2.3em;
  background-color: #eee;
}
.checkbox-wrapper.toggle label:after,
.checkbox-wrapper.toggle label:before {
  top: 9999em;
  position: absolute;
  content: ' ';
  height: 1.3em;
  border: 2px solid #aaa;
  border-radius: 0.65em;
  transition: all 0.1s ease-out;
}
.checkbox-wrapper.toggle label:after {
  width: 1.3em;
  left: 0;
  background-color: #fff;
}
.checkbox-wrapper.toggle input:checked + label:before {
  background-color: #61c6ed;
  border-color: #158cba;
}
.checkbox-wrapper.toggle input:checked + label:after {
  left: 1em;
  border-color: #158cba;
}

.form-group .checkbox-wrapper label {
  font-size: initial;
  opacity: 1;
  position: static;
  transform: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.dropzone > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px #ced4da dashed;
  border-radius: 5px;
  height: 150px;
  text-align: center;
}

.dropzone.active > div {
  border-color: #4858e9;
}
