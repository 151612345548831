@media (min-width: 1200px) {
  .container {
    max-width: 1360px;
  }
}

p {
  margin: 0;
}

#header {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

#header .logo {
  max-width: 115px;
}

.hero {
  align-items: center;
  display: flex;
  min-height: 520px;
}

@media (min-width: 1200px) {
  .hero {
    min-height: 800px;
  }
}

.slogan {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 49px;
}

@media (min-width: 1200px) {
  .slogan {
    font-size: 35px;
  }
}

.login-box {
  background-color: #f6fafb;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  min-height: 325px;
}

#footer {
  background-color: #001a3f;
  min-height: 200px;
}

#footer .logo {
  max-width: 80px;
}

.button {
  border-radius: 28px;
  background-color: #4858e9;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 18px;
  text-align: center;
  padding: 18px 15px;
  width: 240px;
}

.button:hover {
  color: #fff;
  text-decoration: none;
}
