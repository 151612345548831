/* BASE */

.calendar {
  padding: 5px 15px 5px 5px;
}

.calendar__row {
  display: flex;
  flex-direction: row;
}
.calendar__cell {
  flex: 1;
}

.calendar__body {
  position: relative;
}
.calendar__cell {
  height: 20px;
}
.calendar__cell--appointment {
  display: flex;
  position: relative;
  border-top: #e5e5e5 solid 1px;
  border-right: #f4f4f4 solid 1px;
}
.calendar__cell--time-spacing {
  flex: 6px 0 0;
  border-right: none;
}
.calendar__cell--day-of-week {
  text-align: center;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.calendar__cell--day-of-week__day {
  font-size: 12px;
}
.calendar__cell--day-of-week__date {
  font-size: 2.5em;
}
.calendar__cell--day-of-week--today .calendar__cell--day-of-week__day,
.calendar__cell--day-of-week--today .calendar__cell--day-of-week__date {
  color: #fc3d39;
}
.calendar__cell--weekend {
  background: #fafafa;
}
.calendar__cell--time-col {
  flex: 50px 0 0;
}
.calendar__cell--time {
  line-height: 1em;
  transform: translateY(-50%);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar__row--deco-last-row {
  height: 0;
}
.calendar__row--deco-last-row .calendar__cell--appointment {
  height: 0;
}
.calendar__appointment {
  border: 1px solid #4858e9;
  border-radius: 9px;
  background-color: #ffffff;
  padding: 4px 6px;
  font-size: 12px;
  position: absolute;
  top: -1px;
  right: -1px;
  left: 0;
  z-index: 1;
  min-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar__appointment.selected {
  background-color: #4858e9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.calendar__appointment__name {
  font-weight: bold;
}
.calendar__current-time {
  height: 1px;
  background-color: #fc3d39;
  position: absolute;
  right: 0px;
  left: 10px;
  z-index: 1;
}

.calendar__current-time__text {
  color: #fc3d39;
  position: absolute;
  top: 50%;
  transform: translate3d(-100%, -50%, 0);
  margin-left: -2px;
}
/* INTERACTIONS */
.calendar__row:hover .calendar__cell--time {
  color: #555;
}
.calendar__row:hover .calendar__cell--time:after {
  background-color: #aaa;
}
.calendar__row:hover + .calendar__row .calendar__cell--time {
  color: #555;
}
.calendar__row:hover + .calendar__row .calendar__cell--time:after {
  background-color: #aaa;
}

.week-selector {
  text-align: center;
  font-size: 12px;
}

.week-selector .arrow {
  margin: 0 10px;
  padding-top: 5px;
}

.date-selector .title {
  color: #001a3f;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
