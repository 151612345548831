@import 'styles/vendor/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap');

@import 'styles/vendor/material-icons.css';
@import 'styles/navbar.css';
@import 'styles/assignment-card.css';
@import 'styles/calendar.css';
@import 'styles/datetimepicker.css';
@import 'styles/input.css';
@import 'styles/portfolio.css';
@import 'styles/invoice.css';
@import 'styles/footer.css';
@import 'styles/modal.css';
@import 'styles/indexview.css';

.link,
.link:visited {
  color: #4858e9;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}

.text-large {
  color: #000000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #fbfbfb;
}

a,
a:visited,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a.unstyled a.unstyled:visited,
a.unstyled:hover,
a.unstyled:active,
a.unstyled:focus,
a.unstyled:active:hover {
  font-style: inherit;
  color: inherit;
  background-color: transparent;
  font-size: inherit;
  text-decoration: none;
  font-variant: inherit;
  font-weight: inherit;
  font-family: inherit;
  border-radius: inherit;
  border: inherit;
  outline: inherit;
}

h1 {
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}

h1:before {
  content: '';
  position: absolute;
  width: 88px;
  height: 1px;
  bottom: -5px;
  left: 0;
  border-bottom: 4px solid #4858e9;
}

h1.plain:before {
  position: initial;
  border-bottom: 0;
}

h2 {
  color: #000;
  font-size: 13px;
  font-weight: bold;
}

.app-container {
  padding-top: 70px;
  min-height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
}

.app-content {
  flex-grow: 1;
}

.bg-dark {
  background-color: #f6fafb !important;
}

.error {
  color: #ff1212;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 975px) {
  .flex-between-sm {
    display: flex;
    justify-content: space-between;
  }
}
.full-width {
  width: 100%;
  padding: 15px;
}

.list-group.styled .list-group-item {
  border: 1px solid rgba(72, 88, 233, 0.5);
}

.main-color {
  color: #4858e9;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile-pic {
  height: 125px;
  width: 100px;
  background-size: cover;
  background-position: center;
  max-width: 100%;
}

.text-light {
  color: #4e5457;

  font-size: 14px;
  font-weight: 300;
}

.tag {
  border-radius: 9px;
  background-color: #b8c3cc;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  padding: 6px 16px;
}

.tag.small {
  border-radius: 11px;
  padding: 3px 16px;
}

.tag.primary {
  background-color: #4858e9;
}

.tag.success {
  background-color: #39bc75;
}

.tag.dark {
  background-color: #001a3f;
}

.tagline {
  font-size: 12px;
  text-align: center;
  color: #000;
}

.status {
  display: flex;
  align-items: center;
}

.status .icon-bubble {
  color: #fff;
  display: inline;
  padding: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.status .icon-bubble {
  background-color: #4858e9;
}

.chatbubble {
  background-color: #001a3f;
  border-radius: 15px;
  color: #ffffff;
  box-shadow: 0 80px 120px 0 rgba(0, 0, 0, 0.05);
  padding: 15px;
  max-width: 210px;
}

.chatbubble .timestamp {
  font-size: 10px;
  opacity: 0.5;
}

.chatbubble.yours {
  background-color: #fff;
  color: #444444;
  margin-left: auto;
}

.chatbubble p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.78px;
  line-height: 19px;
}

.status .text {
  font-size: 12px;
}

.status .time {
  opacity: 0.5;
  color: #444444;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.77px;
  line-height: 13px;
  text-transform: uppercase;
}

.alerts {
  width: 100%;
  position: fixed;
  z-index: 9999;
  bottom: 25px;
  padding: 0 15px;
}

.banner {
  background-color: #b8c3cc;
  height: 225px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.banner .banner-text {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.form-group {
  position: relative;
  padding-top: 20px;
  margin-top: -10px;
}

.form-group.has-hint {
  margin-bottom: 0;
}

.form-group label {
  opacity: 0.5;
  color: #4e5457;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
  top: 0;
  left: 15px;
  position: absolute;
  transform: translate(0, 38px) scale(1);
  z-index: 99;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.form-group.shrink label {
  transform: translate(0, 1.5px) scale(0.85);
  transform-origin: top left;
}

.form-group input {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 15px;
}

.form-group input:focus {
  border: 0;
  box-shadow: none;
  border: 1px solid #001a3f;
}

.input-root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  line-height: 1.1875em;
  width: 100%;
}

.btn {
  padding: 10px 30px;
  border-radius: 28px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:disabled,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4858e9;
  border-color: #4858e9;
}

.btn.small {
  padding: 10px 15px !important;
  font-size: 14px !important;
}

.btn-primary.extra-small {
  padding: 5px 11px !important;
  font-size: 12px !important;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
}

.registration {
  padding-top: 100px;
}

.glogin {
  background-color: #fff;
  display: inline-flex;
  color: #1f1f1f;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

@media only screen and (min-width: 768px) {
  .login,
  .registration {
    max-width: 355px;
    margin: auto;
  }
}

.tab-bar {
  background-color: #001a3f;
  padding-top: 10px;
  width: 100%;
}

.tab-bar .tab-item {
  color: #fff;
  font-size: 12px;
  opacity: 50%;
  padding: 10px 15px;
}

.tab-bar .tab-item.active {
  background-color: #fbfbfb;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #001a3f;
  opacity: 100%;
}

.tab {
  display: none;
}

.tab.active {
  display: block;
  position: relative;
}

.statusbar {
  background-color: rgb(0, 26, 63);
  color: rgb(255, 255, 255);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;
}

.services .service-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex: 1 0 21%; */

  color: #001a3f;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
  height: 111px;
  width: 110px;
  border: 1px solid rgba(0, 26, 63, 0.1);
  border-radius: 19px;
  background-color: #fbfbfb;
  margin: 7px;
}

.services .service-button svg {
  fill: #001a3f;
}

.services .service-button.selected svg {
  fill: #fff;
}

.services .service-button.selected,
.services .service-button.selected i {
  background-color: #4858e9;
  color: #fff;
}

.confirmation-table {
  display: flex;
  flex-direction: column;
}

.confirmation-table .cell {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.confirmation-table .cell .icon {
  margin-right: 10px;
}

.confirmation-table .cell .text {
  font-size: 12px;
}

.confirmation-table .cell.slide {
  transition: 1s;
  top: 0;
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.overlay.visible {
  display: block;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.open {
  display: block;
}

.modal-content {
  background-color: rgba(0, 26, 63, 0.04);
  margin: 185px auto;
  border: 1px solid #888;
  width: 92%;
  border-radius: 17px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  z-index: 150;
}

.toggle-icon {
  position: absolute;
  right: 30px;
  top: 25px;
  font-size: 40px !important;
}

.profile-text {
  line-height: 18px;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.profile-text small {
  color: #4e5457;
  font-size: 10px;
}

.product-box {
  border: 1px solid rgba(72, 88, 233, 0.5);
  border-radius: 19px;
  background-color: rgba(72, 88, 233, 0.05);
}

.products .product {
  padding: 15px 5px;
  margin-left: -5px;
  margin-right: -5px;
}

.products .product.selected {
  background-color: #edeef9;
  border-radius: 5px;
}

.products .title {
  font-size: 12px;
  font-weight: 600;
}

.products .description {
  color: #000000;

  font-size: 12px;
  font-weight: 300;
}

.products .price {
  color: #4858e9;
  font-size: 14px;
  font-weight: 600;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #4858e9;
  border-right: 1.1em solid #4858e9;
  border-bottom: 1.1em solid #4858e9;
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.user-list {
  list-style-type: none;
  padding: 0;
}

.user-list a:hover,
.user-list a:visited {
  text-decoration: none;
}

.user-list-item {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.user-list-item:hover {
  background-color: #fbfbfb;
}

.user-list-item-label {
  color: #505050;
  font-weight: 600;
}
