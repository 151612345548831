.ReactModal__Body--open {
  overflow: hidden;
}

.react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.react-modal-container {
  background-color: #fff;
  margin: 0 20px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  position: relative;
}

.react-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

@media screen and (min-width: 769px) {
  .react-modal-container {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
  }
}
